









































































import { defineComponent } from '@nuxtjs/composition-api'
import { SfFooter, SfIcon } from '@storefront-ui/vue'
import { PageCategory } from '~/types/PageCategory'
import { Footer, FooterScreenReader, General } from '~/types/homePage/HomepageCategories'
import { loginFooterMock } from '~/mocks/loginFooter.mock'
import DsbFooterColumn from "~/components/AppFooter/DsbFooterColumn.vue";
import DsbFooterColumnContainer from "~/components/AppFooter/DsbFooterColumnContainer.vue";

export default defineComponent({
  name: 'LoginFooter',
  components: {
    DsbFooterColumnContainer,
    SfFooter,
    SfIcon,
    DsbFooterColumn
  },
  setup () {
    const footerItems = loginFooterMock

    const currentYear = new Date().getFullYear();

    return {
      footerItems,
      PageCategory,
      Footer,
      FooterScreenReader,
      General,
      currentYear
    }
  },
})
